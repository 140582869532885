<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <Panel header="Balance General">
        <BasicFormToolbar
        @refresh="refresh" @search="search"
        :actions="['refresh','export', 'search']" />
        <div class="grid">
          <div class="col-12">
                <Fieldset legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormCalendar  wrapperClass="field col-2"  label="Año / Mes" v-model="entity.date"  :view="'month'" :dateFormat="'yy / mm'" />
                    <div class="field col-10">
                      <label for="">Tipos</label>
                      <MultiSelect v-model="selectedTypes" :filter="true" :options="types" display="chip" />
                    </div>
                     <div class="col-2">
                        <FileIcon width="3.5rem" extension="xlsx" @click="downloadXLSX" />
                        <FileIcon width="3.5rem" extension="pdf" @click="downloadPDF"/>
                    </div>
                  </div> 
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Balance">
                  <Card>
                  <template #content>
                    <div class="grid">
                    <!-- ACTIVOS -->
                    <div class="col-6">
                      <Fieldset :collapsed="true" v-for="selectedType in activos" :key="selectedType" class="account-tree" :toggleable="true">
                          <template #legend>
                            {{selectedType}} [ {{getSum(tree[selectedType])}} ]
                          </template>
                        <TreeTable class="account-tree" v-if="tree" :value="tree[selectedType]">
                            <Column field="name" header="Cuenta" :expander="true"></Column>
                            <Column style="width: 8rem;" field="payment" header="Saldo">
                            <template #body="slotProps">
                              {{getValue(slotProps.node.data.final_balance)}}
                            </template>
                            </Column>
                            <template #footer>
                                <div style="text-align:right">
                                    Total {{selectedType}}: {{getSum(tree[selectedType])}}
                                </div>
                            </template>
                        </TreeTable>
                      </Fieldset>
                    </div>
                    <div class="col-6">
                      <Fieldset :collapsed="true" v-for="selectedType in pasivo_capital" :key="selectedType" class="account-tree" :toggleable="true">
                          <template #legend>
                            {{selectedType}} [ {{getSum(tree[selectedType])}} ]
                          </template>
                        <TreeTable class="account-tree" v-if="tree" :value="tree[selectedType]">
                            <Column field="name" header="Cuenta" :expander="true"></Column>
                            <Column style="width: 8rem;" field="payment" header="Saldo">
                            <template #body="slotProps">
                              {{getValue(slotProps.node.data.final_balance)}}
                            </template>
                            </Column>
                            <template #footer>
                                <div style="text-align:right">
                                    Total {{selectedType}}: {{getSum(tree[selectedType])}}
                                </div>
                            </template>
                        </TreeTable>
                      </Fieldset>
                    </div>
                  </div>
                  </template>
                  <template #footer>
                    <div class="grid">
                      <div class="col-6">Total Activos: <strong>{{format(getSumGeneral(activos))}}</strong></div>
                      <div class="col-6">Total Pasivos y Capital: <strong>{{format(getSumGeneral(pasivo_capital))}}</strong></div>
                      <div class="col-6"></div>
                      <div class="col-6">Total: <strong>{{format(getSumGeneral(activos) + getSumGeneral(pasivo_capital))}}</strong> </div>
                    </div>
                  </template>
                  </Card>
                </Fieldset>
          </div>
        </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
var math = require('mathjs');
import { AccountingLedgerAccountClosure } from "../../../models/contabilidad/AccountingLedgerAccountClosure";
import {
  HeaderGrid,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import FileIcon from "../../../components/general/FileIcon.vue";

import Session from "../../../mixins/sessionMixin";
// import moment from 'moment';
export default {
  mixins: [Session],
  data() {
    return {
      types: [],
      selectedTypes: [],
      entity: {
        company: null,
        branch: null,
        date: new Date(),
        level: 0
      },
      tree: null,
      parent: null,
      centres: [],
      filters: {},
      entities: [],
      headers: [
        new HeaderGrid("Clave", "key_type"),
        new HeaderGrid("Folio", "id_number"),
        new HeaderGrid("Fecha", "date", { type: 'date' }),
        new HeaderGrid("Tipo", "accounting_policy_type_name"),
        new HeaderGrid("Descripcion", "description"),
        new HeaderGrid("Cargo", "charges_total", { type: 'decimal' , function: 'SUM'}),
        new HeaderGrid("Abono", "payment_total", { type: 'decimal' , function: 'SUM'}),
        new HeaderGrid("Estatus", "status_name"),
      ],
      loading: false,
    };
  },
  components: { FileIcon, FormCalendar,Loader,BasicFormToolbar },
  created() {
    this.entity = {
      branch: null,
      company: null,
      date: new Date(),
      level: 0
    };
  },
  async mounted() {
    await this.refresh();
  },
  computed: {
    activos() {
      let actives = this.entities
        .filter(e => e.level == 0 && e.accounting_group_type.includes("BALANCE") && e.accounting_type.includes("ACTIVO"))
        .filter(e => this.selectedTypes.find(x => x.toUpperCase().includes(e.name.toUpperCase())))
        .sort((a,b) => a.order_by - b.order_by)
        .map(m => m.name.toUpperCase());
      return actives;
    },
    pasivo_capital() {
      let pasivos_capitales = this.entities
        .filter(e => e.level == 0 && e.accounting_group_type.includes("BALANCE") && 
          (e.accounting_type.includes("PASIVO") || e.accounting_type.includes("CAPITAL")))
        .filter(e => this.selectedTypes.find(x => x.toUpperCase().includes(e.name.toUpperCase())))
        .sort((a,b) => a.order_by - b.order_by)
        .map(m => m.name.toUpperCase());
      return pasivos_capitales;
    }
  },
  methods: {
    new() {
      this.entity = {
        branch: null,
        company: null,
        date: new Date()
      };
    },
    getActivo() {
      if (this.tree) {
        return this.getValue('Activo ' + this.tree.ACTIVO);
      } 
      else {
        return 'Activo'
      }
    },
    async downloadPDF() {
      try {
        if (this.entities.length == 0) {
          throw "No existen registros";
        }
        this.loading = true;
        await new AccountingLedgerAccountClosure(this.session).balancePDF(this.entities);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async search() {
      this.loading = true;
      try {
        this.entity.branch = this.session.branch;
        this.entity.company = this.session.company;
        this.entities = await new AccountingLedgerAccountClosure(this.session).balance(
          this.entity.date.getFullYear(), 
          this.entity.date.getMonth() + 1);
        let localEntities = [];
        this.entities.forEach(element => {
          localEntities.push({...element})
        });
        localEntities = localEntities.filter(x => x.level != 0 && x.accounting_group_type.toUpperCase() == "BALANCE");
        const types = [...new Set(localEntities.map(item => item.type))];
        let tree = {};
        types.forEach(type => {
          tree[type] = localEntities.filter(x => x.type == type && x.level == 1).map(x => {return { key: x.id, data: {...x}, children: []}});
          tree[type].forEach(e1 => {
          e1.children = localEntities.filter(x1 => x1.id_accounting_ledger_account_parent == e1.key).map(x => {return { key: x.id, data: {...x}, children: []}});
          e1.children.forEach(e2 => {
            e2.children = localEntities.filter(x2 => x2.id_accounting_ledger_account_parent == e2.key).map(x => {return { key: x.id, data: {...x}, children: []}});
            e2.children.forEach(e3 => {
              e3.children = localEntities.filter(x3 => x3.id_accounting_ledger_account_parent == e3.key).map(x => {return { key: x.id, data: {...x}, children: []}});
              e3.children.forEach(e4 => {
                e4.children = localEntities.filter(x4 => x4.id_accounting_ledger_account_parent == e4.key).map(x => {return { key: x.id, data: {...x}, children: []}});
                e4.children.forEach(e5 => {
                  e5.children = localEntities.filter(x5 => x5.id_accounting_ledger_account_parent == e5.key).map(x => {return { key: x.id, data: {...x}, children: []}});
                  e5.children.forEach(e6 => {
                    e6.children = localEntities.filter(x6 => x6.id_accounting_ledger_account_parent == e6.key).map(x => {return { key: x.id, data: {...x}, children: []}});
                    e6.children.forEach(e7 => {
                      e7.children = localEntities.filter(x7 => x7.id_accounting_ledger_account_parent == e7.key).map(x => {return { key: x.id, data: {...x}, children: []}});
                      e7.children.forEach(e8 => {
                        e8.children = localEntities.filter(x8 => x8.id_accounting_ledger_account_parent == e8.key).map(x => {return { key: x.id, data: {...x}, children: []}});
                      });
                    });
                  });
                });
              });
            });
          });
        });
        });
        this.tree = tree;
        this.types = types;
        //SIEMPRE EMPEZARA ASÍ, HAY QUE VER COMO LE HACEMOS EN VIDA REAL
        this.selectedTypes = types;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    getValue(value) {
      return Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
            }).format(value);
    },
    getSumGeneral(types) {
      let sum = 0;
      types.forEach(type => {
        let nodes = this.tree[type];
        let internalSum = nodes.reduce((a, b) => {
          return math.chain(a).add(b.data.final_balance ?? 0.0).done();
        }, 0);
        sum = math.chain(sum).add(internalSum).done();
      });
      return sum;
    },
    format(val) {
        return Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
        }).format(val);
    },
    getSum(nodes) {
      let sum = nodes.reduce((a, b) => {
        return math.chain(a).add(b.data.final_balance ?? 0.0).done();
      }, 0);

      return Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
            }).format(sum);
    },
    async refresh() {
      this.loading = true;
      try {
        console.log('empty');
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.account-tree {
  font-size: small;
  margin-bottom: 2rem;
}
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
